  export const state = () => ({
    filter: {
      active: false,
      products: {
        title: 'Type'
      },
      models: {
        title: 'Modellen',
        models: []
      },
      categories: {
        title: 'Genres',
        categories: []
      },
      language: {
        title: 'Taal'
      },
      sort: {
        title: 'Sorteren'
      },
    }
  })

  export const actions = {

  }
  export const getters = {

  }

  export const mutations = {
    setSort(state, sortQuery) { 
      let sort = {
        title: sortQuery.title,
      }
      sort[sortQuery.name] = sortQuery.direction;
      state.filter.sort = sort;
      state.filter.active = true;
    },
    setFilter(state, filterQuery) { 
      if (!state.filter[filterQuery.type]) {
        state.filter[filterQuery.type] = {
          title: filterQuery.title,
          [filterQuery.type]: []
        };
      }

      let existingFilters = state.filter[filterQuery.type][filterQuery.type] || [];

      if (existingFilters.includes(filterQuery.id)) {
        existingFilters = existingFilters.filter(id => id !== filterQuery.id);
      } else {
        existingFilters.push(filterQuery.id);
      }

      state.filter[filterQuery.type] = {
        title: filterQuery.title,
        [filterQuery.type]: existingFilters
      };

      state.filter.active = existingFilters.length > 0;

      if (existingFilters.length === 0) {
        const typeMap = {
          products: { title: 'Type' },
          models: { title: 'Modellen', models: [] },
          categories: { title: 'Genres', categories: [] },
          language: { title: 'Taal' },
          sort: { title: 'Aanbevolen' },
        };
        state.filter[filterQuery.type] = typeMap[filterQuery.type];
      }
    },
    setFilterNew(state, filterQuery) { 
      state.filter[filterQuery.type] = filterQuery;
    },
    toggleFilterState(state, todo) {
      state.filter.active = todo
    },
    reset(state, type) {
      console.log('reset');
      const typeMap = {
        products: { title: 'Type' },
        models: { title: 'Modellen', models: [] },
        categories: { title: 'Genres', categories: [] },
        language: { title: 'Taal' },
        sort: { title: 'Sorteren' },
      };
      if (type === ' all') {
        state.filter = typeMap;
      }
      if (typeMap[type]) {
        state.filter[type] = typeMap[type];
      }
      if (
        state.filter.products.title === 'Type' && 
        state.filter.models.title === 'Modellen' && 
        state.filter.categories.title === 'Genres' && 
        state.filter.language.title === 'Taal' && 
        state.filter.sort.title === 'Sorteren'
        ) {
        state.filter.active = false;
    }
  }

}