export default function ({ route, redirect }) {
  const pathMatch = route.path.match(/^\/(?:categorieen|programmas)(?:\/([\w-]+))?$/);
  if (pathMatch) {
    const slug = pathMatch[1];
    const newPath = route.path.includes('categorieen')
    ? slug
    ? `/genres/${slug}`
    : '/genres'
    : slug
    ? `/series/${slug}`
    : '/series';
    redirect(newPath);
  }
}