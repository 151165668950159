import { render, staticRenderFns } from "./error.vue?vue&type=template&id=b5a1b87a&scoped=true"
import script from "./error.vue?vue&type=script&lang=js"
export * from "./error.vue?vue&type=script&lang=js"
import style0 from "./error.vue?vue&type=style&index=0&id=b5a1b87a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b5a1b87a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Breadcrumbs: require('/home/forge/dev.meidenvanholland.nl/components/breadcrumbs.vue').default})
