//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

  import { mapState } from 'vuex';
  import { checkWindow, checkIsIeEdge } from '~/helpers/device.js';
  export default {
  // async fetch() {
  //   await Promise.all([
  // // this.$store.dispatch('notificationStore/getNotifications')
  //     ])
  // },
    middleware: ['device-detection'],
    components: {
      cookie: () => import('~/components/cookie'),
      Header: () => import('~/components/header/Header'),
      Footer: () => import('~/components/Footer'),
      Navigation: () => import('~/components/Navigation'),
      account: () => import('~/components/user/account_modal'),
      ppmStatus: () => import('~/components/ppmstatus'),
      alertHandler: () => import('~/components/alert'),
    },
    computed: {
      ...mapState({
        resource_item: ({ loadstateStore: { resource_item } }) => resource_item,
        modal: ({ modalStore: { modal } }) => modal,
        alert: ({ alertStore: { alert } }) => alert,
        playerState: ({ mxmStore: { playerState } }) => playerState,
        notifications: ({ notificationStore: { notifications } }) =>
        notifications,
        newNotifications: ({ notificationStore: { newNotifications } }) =>
        newNotifications,
      }),
      mobile: function () {
        return this.isMobile;
      },
    },
    data() {
      return {
        lastPos: 0,
        alertActive: false,
        phablet: false,
        currentDate: new Date(),
      };
    },
    watch: {
      $route: function () {
        this.switchScroll('on');
        this.$store.commit('dropdownStore/setDropdown', false);
      // this.checkReadNotifications();
      },
      mobile: function () {
        if (this.mobile) {
          this.phablet = true;
        }
      },
      modal: {
        handler() {
          if (this.modal) {
            this.switchScroll('off');
          } else {
            this.switchScroll('on');
          }
        },
        deep: true,
      },
      playerState: {
        handler() {
          if (
            (this.playerState.type != 'preroll' &&
              this.playerState.type != 'trailer' &&
              this.playerState.type != 'private' &&
              this.playerState.status == 'playing' &&
              !this.isMobile &&
              !this.smallDevice) ||
            (this.playerState.type != 'preroll' &&
              this.playerState.type != 'private' &&
              this.playerState.type != 'trailer' &&
              this.playerState.status == 'pause' &&
              !this.isMobile &&
              !this.smallDevice)
            ) {
            this.switchScroll('off');
        } else {
          this.switchScroll('on');
        }
      },
      deep: true,
    },
    alert: {
      handler() {
        this.alertActive = true;
        var self = this;
        var time = 5000;
        if (this.alert && this.alert.timeout) {
          time = this.alert.timeout;
        }
        setTimeout(function () {
          self.alertActive = false;
        }, time);
      },
      deep: true,
    },
    notifications: {
      handler() {
        // this.checkReadNotifications();
      },
      deep: true,
    },
  },
  methods: {
    checkMobile() {
      if (this.isMobile) {
        this.phablet = true;
      }
    },
    checkReadNotifications() {
      if (!this.$auth.loggedIn && this.newNotifications) {
        var self = this;
        this.notifications.forEach(function (notification) {
          var checkRead = self.getCookie(notification.hash);
          if (checkRead) {
            self.$store.commit(
              'notificationStore/readThroughCookie',
              notification
              );
          }
        });
      }
    },
    handleScroll(event) {
      var position = window.scrollY;
      if (position < 0) {
        position = 0;
      }
      if (this.lastPos <= 0) {
        this.lastPos = position;
      }

      if (position > this.lastPos + 100) {
        this.lastPos = position;
        this.$root.scroll = true;
      } else if (position < this.lastPos - 100) {
        this.lastPos = position;
        this.$root.scroll = false;
      }
      if (position > 20) {
        this.$root.notontop = true;
      } else {
        this.$root.notontop = false;
      }

      const distanceFromBottom =
      document.documentElement.scrollHeight -
      window.innerHeight -
      window.scrollY;

      if (distanceFromBottom < 300) {
        this.$store.commit('scrollStore/setAlmostBottom', true);
      } else {
        this.$store.commit('scrollStore/setAlmostBottom', false);
      }
    },
  },
  created() {
    if (process.client) {
      this.$store.commit('deviceStore/setIsSmallDevice', checkWindow());
      this.$store.commit('deviceStore/setIsIeEdge', checkIsIeEdge());
    }
  },
  mounted: function () {
    window.addEventListener('scroll', this.handleScroll);
    this.checkMobile();
    this.updateUserLoginStatus();
    // this.checkReadNotifications();
    if (this.$route.query.ref) {
      sessionStorage.setItem('ref', this.$route.query.ref);
      this.$store.commit('subscriptionStore/setRef', this.$route.query.ref);
    }
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
};
