import { queries } from '~/helpers/defaultQueries';

export const state = () => ({
  pages: {
    homepage: false,
    direct: false,
    sexfilms: false,
    freesexfilms: false,
    categorieen: false,
    modellen: false,
    programmas: false,
    webcamsex: false,
    gids: false,
    over: false,
    aanmelden: false,
    faq: false,
    terms: false,
    feedback: false,
    nieuwsbrief: false,
    privacy: false,
    contact: false,
    sexlink: false,
    nieuws: false,
    privefilmpjes: false,
    provider: false,
    energie: false,
    betaald: false,
    inmeikiesjij: false
  },  
});

export const actions = {
  async getPage({ commit }, slug) {
    try {
      const response = await this.$axios.get(`${process.env.API_URL}${process.env.API_PREFIX}/resource/${slug}/nl`);
      commit('setPage', response.data.data);
      return response.data.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
};

export const getters = {
  pages(state) {
    return state.pages;
  },
};

export const mutations = {
  setPage(state, response) {    
    const slug = response.slug.replace(/-/g, '');
    if (!state.pages[slug]) {
      state.pages[slug] = {};
    }
    state.pages[slug] = response;
  },
};
